import React, { useContext } from 'react'
import { Reoverlay } from 'reoverlay'
import InquireModal from 'components/modals/inquireModal'
import Container from 'components/common/Container'
import { CampaignContext } from '../../store'

// parse out the extra text node and return if it exists
const CTAText = ({ data }) => {
  if (!data) return null
  // eslint-disable-next-line react/no-danger
  return <div className="ctaText" dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

function CallToAction({ data }) {
  // console.log('🤣 CallToAction', data, program)
  const { text, buttonText, cssClass } = data
  const slug = useContext(CampaignContext)

  // const signMeUp = () => {
  //   Reoverlay.showModal(InquireModal, {
  //     program: slug,
  //   })
  // }

  return (
    <>
      <CTAText data={text} />
      <Container type="wrapper" className={cssClass}>
        <div>
          {/* <button type="button" className="btn btn-primary" onClick={signMeUp}>
            {buttonText}
          </button> */}
          <a href="#mauticform" className="button">
            {buttonText}
          </a>
        </div>
      </Container>
    </>
  )
}

export default CallToAction

import React from 'react'
import ContentfulComponentCallToActionCta from 'components/content-modules/CTA'
import ContentfulComponentHeadline from 'components/content-modules/Headline'
import ContentfulComponentParagraph from 'components/content-modules/Paragraph'
import ContentfulLayoutSetOfTwo from 'components/content-modules/SetOfTwo'
import ContentfulLayoutSetOfThree from 'components/content-modules/SetOfThree'
import ContentfulComponentImage from 'components/content-modules/Image'
import ContentfulHeroImage from 'components/content-modules/HeroImage'
import ContentfulComponentTestimonialQuote from 'components/content-modules/TestimonialQuote'
import ContentfulComponentParagraphWithHeadline from 'components/content-modules/ParagraphWithHeadline'
import ContentfulComponentStatBlocks from 'components/content-modules/StatBlock'
import ContentfulComponentCard from 'components/content-modules/Card'
import ContentfulHeader from 'components/content-modules/Header'
import ContentfulLayoutSection from 'components/content-modules/Section'
import ContentfulZJsObject from 'components/content-modules/JSObject'
// map over components and return only the ones that are enabled
const componentsMap = {
  ContentfulComponentHeadline,
  ContentfulComponentCallToActionCta,
  ContentfulComponentParagraph,
  ContentfulLayoutSetOfTwo,
  ContentfulLayoutSetOfThree,
  ContentfulComponentImage,
  ContentfulHeroImage,
  ContentfulComponentTestimonialQuote,
  ContentfulComponentParagraphWithHeadline,
  ContentfulComponentStatBlocks,
  ContentfulComponentCard,
  ContentfulHeader,
  ContentfulLayoutSection,
  ContentfulZJsObject,
}

export const getLandingPageModules = (module, index) => {
  // console.log(`🚀 module ${index}: ${module.internal.type}`, module)
  const Component = componentsMap[module.internal.type]
  if (Component) {
    return <Component data={module} index={index} key={index} />
  }
  return null
}

export default getLandingPageModules

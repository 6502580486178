/* eslint-disable react/no-danger */
import React from 'react'

function Paragraph({ data }) {
  const { html } = data.paragraph.childMarkdownRemark
  return (
    <div
      className="text-section"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default Paragraph

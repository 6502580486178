import React from 'react'

import getLandingPageModules from 'utils/getLandingPageModules'
import { Column } from 'components/StyledComponents/Column'
import { Row } from 'components/StyledComponents/Row'
import Container from 'components/common/Container'

function TwoColumn({ data }) {
  const { section1, section2, cssClass, title } = data
  return (
    <Container constraints="centered" className={cssClass}>
      <Row className="row row-twoColumn ">
        <Column className="firstColumn">{section1 && getLandingPageModules(section1)}</Column>
        <Column className="secondColumn">{section2 && getLandingPageModules(section2)}</Column>
      </Row>
    </Container>
  )
}

export default TwoColumn

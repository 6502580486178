import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const StyledDescription = styled.div`
  /* font-size: 1.5rem; */
`

const Title = ({ data }) => {
  if (!data) return null
  return <div className="card-title" dangerouslySetInnerHTML={{ __html: data }} />
}
const Description = ({ data }) => {
  if (!data) return null
  return <StyledDescription className="card-description" dangerouslySetInnerHTML={{ __html: data }} />
}

const Card = ({ title, description, image }) => (
  <div className="card-container">
    <Title data={title} />
    <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />
    <Description data={description} />
  </div>
)

export default Card

/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react'
import useScript from 'hooks/useScript'
import InnerHTML from 'dangerously-set-html-content'

const AcquiaComponent = ({ src }) => {
  const scriptRef = useRef(null)
  const status = useScript(src, scriptRef)

  return (
    <div ref={scriptRef} id="mauticform" className="acquia">
      <p>Let’s stay in touch. We'd love to answer your questions and get to know you during your college search!</p>
      {status === 'loading' && 'Loading...'}
      {status === 'error' && 'Error'}
    </div>
  )
}

const JSObject = ({ data }) => {
  const { src, type, id } = data.json

  if (type === 'acquia') {
    return <AcquiaComponent src={src} />
  }
  if (type === 'slate') {
    // const x = `<div id='form_${id}'>Loading...</div><script>/*<![CDATA[*/var script = document.createElement("script"); script.async = 1; script.src = "${src}?id=${id}&output=embed&div=form_${id}" + ((location.search.length > 1) ? "&" + location.search.substring(1) : ""); var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`
    // hard coding the form for b/c I can't get contentful to not cache the id
    const x = `<div id="form_e3e6aa38-b2ef-4790-a6f8-2f0c33e7c0fb">Loading...</div><script async="async" src=https://admission.susqu.edu/register/?id=e3e6aa38-b2ef-4790-a6f8-2f0c33e7c0fb&amp;output=embed&amp;div=form_e3e6aa38-b2ef-4790-a6f8-2f0c33e7c0fb>/**/</script>`
    // uncomment the below for the EAB form
    // const y = `<div id="form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1">Loading...</div><script>/*<![CDATA[*/var script = document.createElement('script'); script.async = 1; script.src = 'https://apply.collegeofeab.org/register/?id=ed7c8a3f-7cfd-4458-b109-3a210dd58df1&output=embed&div=form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1' + ((location.search.length > 1) ? '&' + location.search.substring(1) : ''); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`

    return (
      <div id="mauticform" className="acquia">
        <p>Let’s stay in touch. We'd love to answer your questions and get to know you during your college search!</p>
        <InnerHTML html={x} />
      </div>
    )
  }
  return null
}

export default JSObject
